import * as React from 'react'
import { Link, graphql } from 'gatsby'

// Components
import Layout from '../../components/layout/Layout'
import MarqueCard from '../../components/card/Marque'

// Marques 
const MarquesPage = ({ pageContext, data }) => {
  const { totalCount } = data.marques
  const pageTitle = `Marques - ${totalCount} marque${
    totalCount === 1 ? "" : "s"
  }`
  const header = `${totalCount} Marque${
    totalCount === 1 ? "" : "s"
  }`

  return (
    <Layout pageTitle={pageTitle} headerClassPosition="sticky-top">
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>{header}</h2>
            <ol>
              <li key="accueil">
                <Link to={`/`} className="text-reset">Accueil</Link>
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            {
              data.marques.nodes.map((marque, index)  => (
                <MarqueCard key={index} data={marque}/>
              ))
            }
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    marques: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/marques/"},
        frontmatter: { actif: { eq: true } }
      },
      sort: {fields: frontmatter___date, order: ASC})
    {
      nodes {
        frontmatter {
          nom
          pays
          site_internet
          hero_image {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          hero_image_16x9 {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          hero_image_alt
          hero_image_credit_link
          hero_image_credit_text
          accueil
          actif
          date(formatString: "MMMM D, YYYY")
        }
        id
        slug
      }
      totalCount
    }
  }
`

export default MarquesPage